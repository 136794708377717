import { Grid, Theme, makeStyles } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import Loader from 'react-loader';
import { SpinnerProperties } from '../../../../assets/themes/spinnerProperties';
import { useBrand } from '../../../hooks/useBrand';
import LegalLinks from '../../elements/LegalLinks';
import TypographyI18N from '../../elements/TypographyI18N';
import { BrandedCardContentProps, defaultProps as defaultContentProps } from './BrandedCardContent';
import { BrandedCardHeaderProps, defaultProps as defaultHeaderProps } from './BrandedCardHeader';
import BrandedCardHelmet from './BrandedCardHelmet';
import BrandedCardLogo from './BrandedCardLogo';

const useStyles = makeStyles<Theme, BrandedCardProps>(() => ({
    // Container that centers the card in the middle of the page and controls the width of the card
    middleContainer: {
        alignContent: 'center',
        alignItems: 'center',
        maxWidth: (props) => props.maxWidth,
        paddingLeft: 0,
        paddingRight: 0,
    },
    // Container that organizes the content of the card vertically and controls the height of the card
    innerContainer: {
        alignContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        justifyContent: 'center',
        minHeight: (props) => props.minHeight,

        // temporary hack until .loadedContent 90% width css rule is removed
        '& .loadedContent': {
            width: '100%',
        },
    },
    loaderText: {
        position: 'relative',
        top: 200,
    },
}));

export type BrandedCardVariant = 'default' | 'large-header';

export type BrandedCardProps = {
    isLoading?: boolean;
    loaderText?: string;
    maxWidth?: number;
    minHeight?: number;
    showLogo?: boolean;
    children: ReactNode[];
    variant?: BrandedCardVariant;
};

const defaultProps: Partial<BrandedCardProps> = {
    isLoading: false,
    maxWidth: 450,
    minHeight: 500,
    variant: 'default',
};

const LARGER_HEADER_DELTA = 20; // Make the header 20px taller and the content 20px shorter

const BrandedCard = (props: BrandedCardProps) => {
    const actualProps = { ...defaultProps, ...props };
    const { showLogo, isLoading, variant } = actualProps;
    const classes = useStyles(actualProps);

    const brand = useBrand();

    let header = actualProps.children.find((child: any) => child.type.displayName === 'BrandedCardHeader');
    let content = actualProps.children.find((child: any) => child.type.displayName === 'BrandedCardContent');
    const actions = actualProps.children.find((child: any) => child.type.displayName === 'BrandedCardActions');

    if (variant === 'large-header') {
        const headerHeight = (header as ReactElement<BrandedCardHeaderProps>).props.height ?? defaultHeaderProps.height;
        header = React.cloneElement(header as ReactElement, {
            height: headerHeight + LARGER_HEADER_DELTA,
        });

        const contentHeight =
            (content as ReactElement<BrandedCardContentProps>).props.height ?? defaultContentProps.height;
        content = React.cloneElement(content as ReactElement, {
            height: contentHeight - LARGER_HEADER_DELTA,
        });
    }

    const logo = showLogo ? <BrandedCardLogo /> : '';

    const spinnerOptions = { ...SpinnerProperties, position: undefined };

    const loaderText = isLoading ? (
        <TypographyI18N className={classes.loaderText}>{actualProps.loaderText}</TypographyI18N>
    ) : null;

    return (
        <Grid container justify='center' alignItems='stretch' direction='column' className='background'>
            <Grid item xs={12}>
                <Grid container justify='center' direction='row'>
                    <Grid item xs={12}>
                        &nbsp;
                    </Grid>
                    <Grid item className={classes.middleContainer} container xs={11}>
                        <Grid container className={classes.innerContainer} item xs={12} direction='row'>
                            <BrandedCardHelmet />
                            {logo}
                            {header}
                            <Loader loaded={!isLoading} options={spinnerOptions}>
                                {content}
                                {actions}
                            </Loader>
                            {loaderText}
                        </Grid>
                    </Grid>
                    <Grid container justify='center' item xs={11}>
                        <LegalLinks brandInfo={brand} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BrandedCard;
