import { Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { ClientConfigContext } from '../../../../clientConfigContext';
import BrandedCard from '../../../containers/BrandedCard/BrandedCard';
import TwoButtonContainer from '../../../containers/TwoButtonContainer';
import CollectDemographicsForm from './CollectDemographicsForm';

import { useMsal } from '@azure/msal-react';
import { AmplitudeEventType } from '../../../../../analytics/amplitudeEvents';
import { BrandProps, ClientConfigType } from '../../../../../clientConfig';
import { DemographicsAuthorizationEvidence } from '../../../../../types/authorizationEvidence';
import { OPENID_PARAMS } from '../../../../../types/openid_enum';
import { LocationState } from '../../../../../types/reactTypes';
import { sendEventByType } from '../../../../services/amplitudeClient';
import { AdditionalAuthParameters, authorizeByDemographics } from '../../../../services/pimsClient';
import { getMyChartSamlSSOFromSystemAndBrand } from '../../../../util/clientSideMyChartUtil';
import getAccessToken from '../../../../util/getAccessToken';
import { redirectWithSoftLogout } from '../../../../util/sessionTimeout';
import TransitionCard from '../../../containers/TransitionCard';
import withLogger, { ComponentLoggerProps } from '../../../HOC/WithLogger';

/**
 * The final page of the authorize by demographics flow. After completion, it returns back to the redirect_uri.
 * In the case of MyChart, we apply cross-EHR logic to redirect the user to the system they authorized in.
 * This might not result in going back to the same redirect_uri. For other applications, there is no concept of cross-EHR.
 */
function CollectDemographics({ brandInfo, log }: BrandProps & ComponentLoggerProps) {
    const [loading, setLoading] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const location = useLocation<LocationState>();
    const searchParams = new URLSearchParams(document.location.search);
    const isCrossRegion: boolean =
        searchParams.get(OPENID_PARAMS.CROSS_REGION) === 'true' || location?.state?.isCrossRegion === true;
    const isMobile = searchParams.get(OPENID_PARAMS.MOBILE) === 'true';
    const redirectUrl =
        searchParams.get(OPENID_PARAMS.REDIRECT_URI) || (searchParams.get(OPENID_PARAMS.REDIRECT_URL) as string);
    const system = searchParams.get(OPENID_PARAMS.EHR_SYSTEM) as string;

    const history = useHistory();
    const { instance, accounts } = useMsal();
    const { errors, control, formState, getValues } = useForm({
        mode: 'onChange',
    });
    const [phoneNumber, setPhoneNumber] = useState('');
    const [proveBypass, setProveBypass] = useState('');

    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);

    const { t } = useTranslation();
    const [jwt, setJwt] = useState('');

    useEffect(() => {
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) => {
            setJwt(jwt);
            sendEventByType(AmplitudeEventType.IdentityVerification_DemographicForm, jwt);
        });
    }, []);
    useEffect(() => {
        termsAccepted && sendEventByType(AmplitudeEventType.IdentityVerification_DemographicForm_Consent, jwt);
    }, [termsAccepted]);

    useEffect(() => {
        setPhoneNumber(location?.state?.phoneNumber);
        setProveBypass(location?.state?.proveBypass);
    }, [location]);

    // Call PIMS AuthorizeByDemographics and then redirect back to the caller. If cross_region is set, then go back to an authorized system.
    const handleSubmit = async () => {
        const data = getValues();
        setLoading(true);
        const authorizationEvidence: DemographicsAuthorizationEvidence = {
            demographics: {
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                dateOfBirth: data.birthdate,
                phoneNumber: phoneNumber,
                last4ssn: data.ssn,
                streetAddress: data.streetAddress,
                city: data.city,
                state: data.state,
                postalCode: data.zipCode,
                locationState: data.state,
            },
        };
        const additionalParameters: AdditionalAuthParameters = {
            brand: brandInfo.brand,
        };
        if (system) {
            additionalParameters.ehrSystem = system;
        }
        try {
            log.debug(
                `Authorize By Demographics: isMobile: ${isMobile}, ehrSystem: ${system}, isCrossRegion: ${isCrossRegion}`,
            );
            const response = await authorizeByDemographics({
                jwt,
                authorizationEvidence,
                pimsApiUrl: clientConfiguration.pimsApiUrl,
                system,
                proveBypass,
                additionalParameters,
            });
            if (response.ok) {
                const body = await response.json();
                if (!body.authorizedSystem) {
                    log.error(`No AuthSystem, redirectUrl: ${redirectUrl}, ehrSystem: ${system}`);
                    history.push('/error');
                    return;
                }

                // Cross Region is for MyChart-SAML flow. No other clients use set cross_region to true
                if (isCrossRegion) {
                    const mychartSamlSSOUrl = getMyChartSamlSSOFromSystemAndBrand(
                        clientConfiguration,
                        body.authorizedSystem,
                        brandInfo.brand,
                    );
                    if (mychartSamlSSOUrl) {
                        redirectWithSoftLogout(instance, mychartSamlSSOUrl);
                    } else {
                        log.error(
                            `Invalid MyChart SAML SSO URL: authorizedSystem: ${body.authorizedSystem}, brand: ${brandInfo.brand}`,
                        );
                        history.push('/error');
                    }
                } else {
                    log.info(`Go to redirectURL: ${redirectUrl}`);
                    redirectWithSoftLogout(instance, redirectUrl);
                }
            } else {
                log.error(
                    `Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}` : ''}`,
                );
                history.push('/error');
            }
        } catch (err: any) {
            log.error(`Error occurred - ${err.message}`);
            history.push('/error');
        }
    };

    const handleBackButtonClick = () => {
        sendEventByType(AmplitudeEventType.IdentityVerification_DemographicForm_Back, jwt);
        history.push('/');
    };

    const { isDirty, isValid } = formState;

    const renderForm = () => {
        if (loading) {
            return <TransitionCard title='Creating your account' />;
        } else {
            return (
                <div className={'displayFlex'}>
                    <BrandedCard maxWidth={711} brand={brandInfo}>
                        {{
                            header: (
                                <>
                                    <Typography variant='h2'>{t('Help us find your medical record')}</Typography>
                                </>
                            ),
                            content: (
                                <>
                                    <CollectDemographicsForm
                                        control={control}
                                        errors={errors}
                                        setTermsAccepted={setTermsAccepted}
                                        brand={brandInfo}
                                        phoneNumber={phoneNumber}
                                    />
                                </>
                            ),
                            actions: (
                                <>
                                    <Grid container justify='space-around' item xs={12}>
                                        <TwoButtonContainer
                                            firstButtonProps={{
                                                variant: 'text',
                                                label: t('Back'),
                                                onClick: handleBackButtonClick,
                                                brand: brandInfo.brand,
                                            }}
                                            secondButtonProps={{
                                                variant: 'contained',
                                                disabled: !isDirty || !isValid || !termsAccepted,
                                                onClick: handleSubmit,
                                                type: 'button',
                                                label: t('Next'),
                                                brand: brandInfo.brand,
                                            }}
                                        />
                                    </Grid>
                                </>
                            ),
                        }}
                    </BrandedCard>
                </div>
            );
        }
    };

    return <>{renderForm()}</>;
}

export default withLogger(CollectDemographics, {
    eventSource: 'SubmitAuthorizeDemographics',
});
