import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect } from 'react';
import { getVerificationSessionCookieName } from '.';
import { ClientConfigType } from '../../../../clientConfig';
import { ClientConfigContext } from '../../../clientConfigContext';
import useCookie from '../../../hooks/useCookie';
import { useOidcState } from '../../../hooks/useOidcState';
import { authorizeByIdentityVerification } from '../../../services/pimsClient';
import getAccessToken from '../../../util/getAccessToken';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import TransitionCard from '../../containers/TransitionCard';
import { AuthenticatedRoutes } from '../../routers';
import { VENDOR_NAME } from './clear';
import { IdentityVerificationState } from './types';

/**
 * The Identity Verification Redirect Page.
 * After completing identity verification, the user is redirected to this page.
 */
export const RedirectPage: React.FC<ComponentLoggerProps> = ({ log }) => {
    const { b2cTenantConfig, pimsApiUrl } = useContext<ClientConfigType>(ClientConfigContext);
    const { instance, accounts } = useMsal();
    const state = useOidcState<IdentityVerificationState>({
        isRequired: true,
        log,
    });

    // Show the new Identity Verification page if the user already has a clear session cookie or if the feature is enabled
    const azureUserId = accounts?.[0]?.localAccountId || '';
    const verificationSessionCookieName = getVerificationSessionCookieName(VENDOR_NAME, azureUserId); // In the future, this could loop over multiple vendors if needed
    const [verificationSessionId] = useCookie(verificationSessionCookieName);

    if (!verificationSessionId || !state || !state.redirect_uri) {
        window.location.replace(AuthenticatedRoutes.VerificationRoutes.VerificationMenu);
    }

    const authorize = async (sessionId: string, redirectUrl: string) => {
        const jwt = await getAccessToken(instance, accounts, b2cTenantConfig.scopes, log);
        try {
            await authorizeByIdentityVerification(jwt, pimsApiUrl, VENDOR_NAME, sessionId);
            // TODO: cross region logic
            window.location.replace(redirectUrl);
        } catch (e) {
            window.location.replace(AuthenticatedRoutes.VerificationRoutes.VerificationMenu);
        }
    };

    useEffect(() => {
        if (verificationSessionId && state) {
            authorize(verificationSessionId, state.redirect_uri);
        }
    }, []);

    return <TransitionCard title='Creating your account' />;
};

export default withLogger(RedirectPage, { eventSource: 'RedirectPage' });
