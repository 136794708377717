import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles<Theme, BrandedCardHeaderStyles, string>(() => ({
    item: {
        alignItems: 'center',
        height: (options) => options.height,
        justifyContent: 'center',
    },
}));

type BrandedCardHeaderStyles = {
    height: number;
};

export type BrandedCardHeaderProps = {
    children: ReactNode[] | ReactNode;
    height?: number;
};

export const defaultProps = {
    height: 100,
};

const BrandedCardHeader: React.FC<BrandedCardHeaderProps> = (props: BrandedCardHeaderProps) => {
    const classes = useStyles({ height: props.height ?? defaultProps.height });
    const { children } = props;
    return (
        <Grid item container xs={12} className={classes.item}>
            {children}
        </Grid>
    );
};
BrandedCardHeader.displayName = 'BrandedCardHeader';
export default BrandedCardHeader;
