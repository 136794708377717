import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import TypographyI18N from '../elements/TypographyI18N';
import BrandedCard, { BrandedCardContent, BrandedCardHeader } from './BrandedCard_v2';

type TransitionCardProps = {
    title: string;
};

/**
 * A card that displays a loading spinner. Used when transitioning from PIP to somewhere else and waiting for some action to finish
 */
const TransitionCard: React.FC<TransitionCardProps> = ({ title }: TransitionCardProps) => {
    return (
        <BrandedCard variant='large-header'>
            <BrandedCardHeader>
                <TypographyI18N variant='h2'>{title}</TypographyI18N>
            </BrandedCardHeader>
            <BrandedCardContent verticallyCentered={true}>
                <Grid container justify='center' item xs={12}>
                    <CircularProgress size={60} />
                </Grid>
            </BrandedCardContent>
        </BrandedCard>
    );
};

export default TransitionCard;
