import React from 'react';
import { useTranslation } from 'react-i18next';
import { AmplitudeEventType } from '../../../../../analytics/amplitudeEvents';
import { PimsPostVerificationSessionRequest } from '../../../../../router/types';
import { sendEventByType } from '../../../../services/amplitudeClient';
import { postIdentityVerificationSession } from '../../../../services/pimsClient';
import Button from '../../../elements/Button';
import { ClearIcon } from './ClearIcon';
import { ClearConfig } from './types';

type ClearButtonProps = {
    config: ClearConfig;
    width: number;
    params: PimsPostVerificationSessionRequest;
    onError: (error: Error) => void;
    onSessionCreated: (verificationSessionId: string) => void;
    onClick?: () => void;
    token?: string;
};

// CLEAR styling UX requirements. See: https://docs.clearme.com/docs/ux-guidelines
const theme = {
    backgroundColor: '#041A55',
    hoverBackgroundColor: '#394168',
    fontFamily: 'Inter',
};

/**
 * A button to navigate to the CLEAR Hosted UI for identity verification.
 * @param config - The configuration for the CLEAR vendor.
 * @param width - The width of the button (in pixels).
 * @param token - A CLEAR session token. If not provided, we will create a new session first, before navigating to the CLEAR Hosted UI.
 * @param newSession - A boolean indicating whether to start a new session or continue an existing session with CLEAR.
 * @param params - The parameters required to create a new CLEAR verification session.
 * @param onError - A callback function to execute when an error occurs.
 * @param onSessionCreated - A callback function to execute when a new session is created.
 */
export const ClearButton: React.FC<ClearButtonProps> = ({
    config,
    token,
    width,
    params,
    onError,
    onSessionCreated,
}: ClearButtonProps) => {
    const { t } = useTranslation();
    const text = token ? 'Continue with CLEAR' : 'Verify with CLEAR';

    const useCreateClearVerificationSessionFlow = async (): Promise<string> => {
        try {
            const response = await postIdentityVerificationSession(params);

            if (!response || !response.sessionTokenId || !response.verificationSessionId) {
                throw new Error(`Failed to create verification session: ${JSON.stringify(response)}`);
            }

            // TODO: decouple this better
            sendEventByType(AmplitudeEventType.IdentityVerification_CreateNewVerificationSession, params.jwt);

            onSessionCreated(response.verificationSessionId);
            return response.sessionTokenId;
        } catch (error) {
            // TODO: decouple this better
            sendEventByType(AmplitudeEventType.IdentityVerification_CreateNewVerificationSession_Fail, params.jwt);
            onError(error as Error);
            return '';
        }
    };

    /**
     * If no token exists already, create a new session and use the new token.
     * If session creation fails, no-op. The parent component should handle the error via the onError callback.
     */
    const handleClick = async () => {
        if (!token) {
            token = await useCreateClearVerificationSessionFlow();
        }
        return window.location.replace(`${config.verifyUrl}?token=${token}`);
    };

    return (
        <Button
            variant='contained'
            activeBackgroundColor={theme.hoverBackgroundColor}
            backgroundColor={theme.backgroundColor}
            fontFamily={theme.fontFamily}
            hoverBackgroundColor={theme.hoverBackgroundColor}
            paddingLeft={0}
            paddingRight={0}
            startIcon={<ClearIcon logoUrl={config.logoUrl} />}
            textTransform='none'
            width={width}
            onClick={handleClick}
        >
            {t(text)}
        </Button>
    );
};
