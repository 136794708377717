import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AmplitudeEventType } from '../../../../analytics/amplitudeEvents';
import { type ClientConfigType } from '../../../../clientConfig';
import { Feature } from '../../../../types/feature';
import { ClientConfigContext } from '../../../clientConfigContext';
import { useFeature } from '../../../hooks/useFeature';
import { sendEventByEventObject, sendEventByType } from '../../../services/amplitudeClient';
import getAccessToken from '../../../util/getAccessToken';
import BrandedCard, {
    BrandedCardActions,
    BrandedCardContent,
    BrandedCardHeader,
} from '../../containers/BrandedCard_v2';
import Button from '../../elements/Button';
import TypographyI18N from '../../elements/TypographyI18N';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';

type VerifyEntryProps = {
    isCrossRegion?: boolean;
};

export const VerifyEntry: React.FC<VerifyEntryProps & ComponentLoggerProps> = ({
    isCrossRegion = false,
    log,
}: VerifyEntryProps & ComponentLoggerProps) => {
    const history = useHistory();
    const { handleSubmit } = useForm({ mode: 'onChange' });
    const [jwt, setJwt] = useState('');
    const { instance, accounts } = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);

    const isFeatureEnabled = useFeature(Feature.ClearIdentityVerification);
    if (isFeatureEnabled) {
        // TODO: Replace with logic to toggle the view
        console.log('Feature is enabled');
    }

    useEffect(() => {
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) => {
            setJwt(jwt);
            const eventPropsData = {
                event_type: AmplitudeEventType.ActivationCode_Prerequisite_Question,
                event_properties: {
                    entry_flow: accounts[0]?.idTokenClaims?.newUser ? 'New Account creation' : 'Existing Account',
                },
            };
            sendEventByEventObject(jwt, eventPropsData);
        });
    }, []);
    const onActivationCodeDeny = () => {
        sendEventByType(AmplitudeEventType.ActivationCode_Prerequisite_Deny, jwt);
        history.push({
            pathname: '/phone',
            // If we arrive here without this is_cross_region query param, we need to propagate it to the next page via the state.
            // TODO: Replace all this propagation with a React context
            state: { isCrossRegion },
        });
    };
    const onActivationCodeSubmit = async () => {
        sendEventByType(AmplitudeEventType.ActivationCode_Prerequisite_Confirm, jwt);
        history.push('/activation');
    };
    const { t } = useTranslation();
    return (
        <BrandedCard maxWidth={452} minHeight={530} isLoading={false}>
            <BrandedCardHeader>
                <></>
            </BrandedCardHeader>
            <BrandedCardContent>
                <div className='txtAlignCenter'>
                    <TypographyI18N variant='h2'>
                        Do you have an activation code from one of our clinics?
                    </TypographyI18N>
                </div>
            </BrandedCardContent>
            <BrandedCardActions>
                <button className='cancelButton' type='button' onClick={onActivationCodeDeny}>
                    {t('No')}
                </button>
                <Button type='button' variant='contained' onClick={handleSubmit(onActivationCodeSubmit)}>
                    {t('Yes')}
                </Button>
            </BrandedCardActions>
        </BrandedCard>
    );
};

export default withLogger(VerifyEntry, { eventSource: 'VerifyEntry' });
