import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { ClientConfigType } from '../clientConfig';

export function initI18N(clientConfig: ClientConfigType) {
    i18n
        // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: {
                espanol: ['es'],
                default: ['en'],
            },
            keySeparator: false,
            debug: false,
            initImmediate: true,
            defaultNS: 'translation',
            backend: {
                loadPath: `${clientConfig.i18nPath || ''}/locales/{{lng}}/{{ns}}.json`,
                crossDomain: true,
            },
            detection: {
                order: ['querystring', 'cookie', 'localStorage', 'navigator'],

                lookupQuerystring: 'lang',
                lookupCookie: 'i18n',
                lookupLocalStorage: 'i18nextLng',

                caches: ['localStorage', 'cookie'],
                excludeCacheFor: ['cimode'],

                cookieMinutes: 10,
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        });
}
