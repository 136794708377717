import React from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import { AuthenticatedRoutes } from '.';
import { MFAVerifyType } from '../../../types/mfa';
import { useBrand } from '../../hooks/useBrand';
import TransitionCard from '../containers/TransitionCard';
import VerifyContactCustomerService from '../containers/VerifyContactCustomerService';
import DebugMenuPage from '../features/DebugMenuPage';
import ActivationCodePage from '../features/activation/ActivationCodePage';
import { AutoInstantActivationAuthorizationPage } from '../features/autoinstantactivation';
import AutoInstantActivationAdditionalInfo from '../features/autoinstantactivation/AutoInstantActivationAdditionalInfo';
import ChangePasswordPage from '../features/changePassword/ChangePasswordPage';
import LinkWithDemographics from '../features/demographics/LinkWithDemographics';
import MfaByEmailPage from '../features/mfaSetup/MfaByEmailPage';
import MfaCodeVerificationPage from '../features/mfaSetup/MfaCodeVerificationPage';
import MfaSetupSuccessPage from '../features/mfaSetup/MfaSetupSuccessPage';
import { AppPromotion } from '../features/promotion/AppPromotion';
import RegisterPage from '../features/registration/RegisterPage';
import RedirectPage from '../features/verification/RedirectPage';
import CollectDemographics from '../features/verification/demographics/CollectDemographics';
import CollectDemographicsIntro from '../features/verification/demographics/CollectDemographicsIntro';
import CollectPhoneNumberForm from '../features/verification/phone/CollectPhoneNumberForm';
import VerifyPhoneCode from '../features/verification/phone/VerifyPhoneCode';

const AuthenticatedRouter = (): JSX.Element => {
    const brandInfo = useBrand();

    const {
        AutoInstantActivationRoutes,
        ChangePasswordRoutes,
        LinkWithDemographicsRoutes,
        MfaRoutes,
        VerificationRoutes,
        RootRoutes,
    } = AuthenticatedRoutes;

    return (
        <Switch>
            <Route exact path={RootRoutes.DebugMenu}>
                <DebugMenuPage />
            </Route>
            <Route exact path={RootRoutes.Registration}>
                <MemoryRouter>
                    <Switch>
                        <Route exact path={VerificationRoutes.VerificationMenu}>
                            <RegisterPage />
                        </Route>
                        <Route path={VerificationRoutes.ActivationCodeFlow}>
                            <ActivationCodePage brandInfo={brandInfo} />
                        </Route>
                        <Route path={VerificationRoutes.StartDemographicsFlow}>
                            <CollectPhoneNumberForm
                                brandInfo={brandInfo}
                                header='Verify identity over SMS text message'
                            />
                        </Route>
                        <Route path={VerificationRoutes.ActivationCodeExpired}>
                            <CollectPhoneNumberForm
                                brandInfo={brandInfo}
                                header='Your activation code is out of date'
                            />
                        </Route>
                        <Route path={VerificationRoutes.SmsVerification}>
                            <VerifyPhoneCode brandInfo={brandInfo} />
                        </Route>
                        <Route path={VerificationRoutes.DemographicsIntro}>
                            <CollectDemographicsIntro brandInfo={brandInfo} />
                        </Route>
                        <Route path={VerificationRoutes.DemographicsForm}>
                            <CollectDemographics brandInfo={brandInfo} />
                        </Route>
                        <Route path={VerificationRoutes.IdentityCreation}>
                            <TransitionCard title='Creating your account' />;
                        </Route>
                        <Route path={VerificationRoutes.ContactCustomerService}>
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path={RootRoutes.AutoInstantActivation}>
                <MemoryRouter>
                    <Switch>
                        <Route exact path={AutoInstantActivationRoutes.AuthorizationForm}>
                            <AutoInstantActivationAuthorizationPage />
                        </Route>
                        <Route path={AutoInstantActivationRoutes.AppPromotion}>
                            <AppPromotion brandInfo={brandInfo} />
                        </Route>
                        <Route path={AutoInstantActivationRoutes.AdditionalInfoNeeded}>
                            <AutoInstantActivationAdditionalInfo />
                        </Route>
                        <Route path={AutoInstantActivationRoutes.ContactCustomerService}>
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path={RootRoutes.LinkWithDemographics}>
                <MemoryRouter>
                    <Switch>
                        <Route exact path={LinkWithDemographicsRoutes.Form}>
                            <LinkWithDemographics brandInfo={brandInfo} />
                        </Route>
                        <Route path={LinkWithDemographicsRoutes.ContactCustomerService}>
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path={RootRoutes.ChangePassword}>
                <MemoryRouter>
                    <Switch>
                        <Route exact path={ChangePasswordRoutes.Start}>
                            <ChangePasswordPage brandInfo={brandInfo} />
                        </Route>
                        <Route path={ChangePasswordRoutes.ContactCustomerService}>
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path={RootRoutes.MfaRegistration}>
                <MemoryRouter>
                    <Switch>
                        <Route exact path={MfaRoutes.RegisterEmail}>
                            <MfaByEmailPage brandInfo={brandInfo} />
                        </Route>
                        <Route path={MfaRoutes.VerifyEmail}>
                            <MfaCodeVerificationPage brandInfo={brandInfo} verifyType={MFAVerifyType.EmailVerifyType} />
                        </Route>
                        <Route path={MfaRoutes.ContactCustomerService}>
                            <VerifyContactCustomerService />
                        </Route>
                        <Route path={MfaRoutes.Success}>
                            <MfaSetupSuccessPage brandInfo={brandInfo} />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path={RootRoutes.VerificationRedirect}>
                <RedirectPage />
            </Route>
        </Switch>
    );
};

export default AuthenticatedRouter;
