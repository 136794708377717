import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {ThemeProvider} from '@material-ui/core/styles';

import {MsalProvider} from "@azure/msal-react";
import {Configuration, PublicClientApplication} from "@azure/msal-browser";

import './index.css';
import App from './clientSide/components/App';
import {ClientConfigContext} from "./clientSide/clientConfigContext";
import { type ClientConfigType, type ClientContext } from './clientConfig';

import {fetchClientConfiguration} from "./clientSide/services/fetchClientConfig";
import {OPENID_PARAMS} from "./types/openid_enum";
import {ClientLogger} from '@Praia-Health/spi-common-utils/dist/logging/clientLogger';
import {ApiRequestLogger} from "./clientSide/logger/apiRequestLogger";
import {AmplitudeClientConfig} from '@Praia-Health/spi-amplitude-client/dist/types/amplitudeConfig';
import {initAmplitude} from './clientSide/services/amplitudeClient';
import getTheme from './clientSide/util/getTheme';
import {setClientCorrelationId, storeLogMetadata} from "./clientSide/logger/logMetadata";
import { initI18N } from './utils/i18nUtil';

const searchParams = new URLSearchParams(document.location.search);
const queryCorrelationId = searchParams.get(OPENID_PARAMS.CORRELATION_ID);
const amplitudeDeviceId = searchParams.get(OPENID_PARAMS.AMPLITUDE_DEVICE_ID) || undefined;
const amplitudeSessionId = searchParams.get(OPENID_PARAMS.AMPLITUDE_SESSION_ID) || undefined;


// Initialize PublicClientApplication.Configuration
function renderAppWithConfiguration(response: AxiosResponse, clientContext: ClientContext) {
    console.log("Render App With Configuration");
    if (queryCorrelationId) { // if there is a correlationId query parameter, assign this to the cookie to override it.
        setClientCorrelationId(queryCorrelationId);
    }
    storeLogMetadata();

    const clientConfig: ClientConfigType = response.data;
    const branding = clientConfig.brandConfig;
    if (!branding) {
        throw new Error("No branding found for the given brand");
    }

    const brand = branding.brand;
    const isMobile = clientContext.deviceCategory === "Mobile";

    const signInPolicy = clientConfig.b2cTenantConfig.signInPolicies.find((policy) => {
        return policy.name === branding?.signInPolicy
    });

    // Initializing MSAL Configuration
    const azureHost = `${clientConfig.b2cTenantConfig.tenantName}.b2clogin.com`;
    const signInAuthority = `https://${azureHost}/${clientConfig.b2cTenantConfig.tenantId}/${signInPolicy?.policyId}`;
    const config: Configuration = {
        auth: {
            authority: signInAuthority,
            knownAuthorities: [azureHost],
            clientId: clientConfig.b2cTenantConfig.clientId,
            redirectUri: clientConfig.b2cTenantConfig.redirectUri,
            authorityMetadata: clientConfig.authorityMetadata
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        },
        system: {
            allowRedirectInIframe: true
        }
    };

    initI18N(clientConfig);

    // Branded Theme/UI/Background Change
    const theme = getTheme(branding.theme);
    const backgroundCss = (branding?.backgroundImage) ? `url("${clientConfig.cdnPath}${branding?.backgroundImage}")` : branding?.backgroundColor;
    document.getElementsByTagName('html')[0].style.background = `${backgroundCss} no-repeat center / cover`;
    const favIcon = document.getElementById("favicon");
    if (favIcon) {
        favIcon.setAttribute("href", clientConfig.cdnPath + "/brandeduisignupsignin/images/favicon.ico");
    }

    //Initialize Amplitude related configs
    const maxMessageLength = clientConfig?.loggingConfig?.maxMessageLength;
    const logger: ClientLogger = new ApiRequestLogger({}, maxMessageLength);
    const ampConfig: AmplitudeClientConfig = {
        logger,
        authUrl: clientConfig.amplitudeConfig.authUrl,
        noAuthUrl: clientConfig.amplitudeConfig.noAuthUrl,
        enableAmplitudeEvents: clientConfig.amplitudeConfig.enableEvents,
    };
    initAmplitude(ampConfig, brand, isMobile, amplitudeDeviceId, amplitudeSessionId);
    const pca = new PublicClientApplication(config);

    ReactDOM.render(
        <React.StrictMode>
            <ClientConfigContext.Provider value={clientConfig}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback="loading">
                            <MsalProvider instance={pca}>
                                <App/>
                            </MsalProvider>
                        </Suspense>
                    </ThemeProvider>
                </BrowserRouter>
            </ClientConfigContext.Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

if (window.location.href.includes("/logout")) {
    // [SPI-3821] The single sign out feature loads this logout endpoint in an IFrame.
    // It has a short time window for the following code to load and execute.
    // Do not add lengthy running code in this.
    localStorage.clear();
    sessionStorage.clear();
} else {
    fetchClientConfiguration(renderAppWithConfiguration);
}
