import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LONG_ZIP_CODE, SHORT_ZIP_CODE } from '../../../utils/zipCodeUtils';

export default function zipCodeControl(control: any, errors: any, fullZipCode: boolean, shrink?: boolean) {
    const { t } = useTranslation();
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=''
            name='zipCode'
            rules={{
                required: true,
                pattern: fullZipCode ? LONG_ZIP_CODE : SHORT_ZIP_CODE,
            }}
            fullWidth
            id='zipCode'
            label={
                (errors.zipCode?.type === 'required' && (
                    <div>
                        {t('Zip code ')}
                        <div className='errorLabelAsterisk'>*</div>
                    </div>
                )) ||
                (errors.zipCode?.type === 'pattern' && (
                    <div>
                        {t('Zip code ')}
                        <div className='errorLabelAsterisk'>*</div>
                    </div>
                )) ||
                t('Zip code')
            }
            inputProps={{ maxLength: fullZipCode ? 10 : 5 }}
            error={errors.zipCode?.type === 'required' || errors.zipCode?.type === 'pattern'}
            helperText={
                (errors.zipCode?.type === 'required' && t('Zip code is required')) ||
                (errors.zipCode?.type === 'pattern' && t('Zip code is incorrect')) ||
                ' '
            }
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
            autoComplete='postal-code'
        />
    );
}
