import dayjs from 'dayjs';

export const MAX_AGE = 180;
export const MM_DD_YYYY_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/[0-9]{4}$/;

export function convertDateFormat(date: string) {
    const dateParts = date.split('/');
    return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
}

/**
 * Simple date validator. Requires dates to be valid, in the past, but not too far in the past
 */
export const validateBirthDate = (input: string): boolean => {
    const date = dayjs(input);
    const now = dayjs();

    // Date must be valid. This handles edge cases like 02/31/2024
    if (input !== date.format('MM/DD/YYYY')) {
        return false;
    }

    // Date must be in the past
    if (date.isAfter(now)) {
        return false;
    }

    // Date must not be too far in the past
    if (date.year() < now.year() - MAX_AGE) {
        return false;
    }

    return true;
};
