import { useMsal } from '@azure/msal-react';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getVerificationSessionCookieName } from '.';
import { AmplitudeEventType } from '../../../../analytics/amplitudeEvents';
import { type ClientConfigType } from '../../../../clientConfig';
import { PimsPostVerificationSessionRequest, SessionStatus } from '../../../../router/types';
import { OPENID_PARAMS } from '../../../../types/openid_enum';
import { ClientConfigContext } from '../../../clientConfigContext';
import { useBrand } from '../../../hooks/useBrand';
import useCookie, { setCookie } from '../../../hooks/useCookie';
import { sendEventByEventObject, sendEventByType } from '../../../services/amplitudeClient';
import { getIdentityVerificationSession } from '../../../services/pimsClient';
import { getDeviceCategory } from '../../../util/clientContextUtils';
import { useErrorHistoryPush } from '../../../util/errorUtils';
import getAccessToken from '../../../util/getAccessToken';
import BrandedCard, { BrandedCardActions, BrandedCardContent } from '../../containers/BrandedCard_v2';
import Button from '../../elements/Button';
import TypographyI18N from '../../elements/TypographyI18N';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import { AuthenticatedRoutes } from '../../routers';
import { VENDOR_NAME as CLEAR_VENDOR_NAME, ClearButton, ClearConfig, VENDOR_NAME } from './clear';

type IdentityVerificationPageProps = ComponentLoggerProps & {
    isCrossRegion?: boolean;
    ehrSystem?: string;
};

enum PageState {
    Loading,
    NewSession,
    ExistingSession,
    Redirecting,
}

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 20,
    },
    list: {
        padding: 0,
    },
    listItem: {
        display: 'list-item',
        left: 12,
        listStylePosition: 'inside',
        listStyleType: 'disc',
        marginTop: 0,
        padding: 0,
        position: 'relative',
        '&::marker': {
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    listItemText: {
        margin: 0,
        display: 'inline-flex',
        whiteSpace: 'break-spaces',
        width: 'calc(100% - 28px)',
    },
    listItemTypography: {
        lineHeight: '24px',
    },
}));

/**
 * The Identity Verification Page.
 * Basic identities will arrive at this page when they need an EHR authorization.
 * @param isCrossRegion
 * @param log APIRequestLogger
 */
export const IdentityVerificationPage: React.FC<IdentityVerificationPageProps> = ({
    isCrossRegion = false,
    ehrSystem = '',
    log,
}: IdentityVerificationPageProps) => {
    const history = useHistory();
    const [jwt, setJwt] = useState('');
    const { instance, accounts } = useMsal();
    const { b2cTenantConfig, identityVerification, pimsApiUrl }: ClientConfigType = useContext(ClientConfigContext);
    const [token, setToken] = useState<string | undefined>(undefined);
    const { brand } = useBrand();
    const searchParams = new URLSearchParams(window.location.search);

    const errorHistoryPush = useErrorHistoryPush();

    // Get the redirect URI from the query params
    const redirectUriParam = searchParams.get(OPENID_PARAMS.REDIRECT_URI);
    const redirectUrlParam = searchParams.get(OPENID_PARAMS.REDIRECT_URL);
    const redirectUri = redirectUriParam || redirectUrlParam;

    if (!redirectUri) {
        errorHistoryPush(AuthenticatedRoutes.VerificationRoutes.ContactCustomerService, 'Missing redirect URI');
        return null;
    }

    // Show the new Identity Verification page if the user already has a clear session cookie or if the feature is enabled
    const azureUserId = accounts?.[0]?.localAccountId || '';
    const verificationSessionCookieName = getVerificationSessionCookieName(VENDOR_NAME, azureUserId); // In the future, this could loop over multiple vendors if needed
    const [verificationSessionId] = useCookie(verificationSessionCookieName);
    const [pageState, setPageState] = useState<PageState>(
        verificationSessionId ? PageState.Loading : PageState.NewSession,
    );

    const checkForExistingSession = async () => {
        const jwt = await getAccessToken(instance, accounts, b2cTenantConfig.scopes, log);
        setJwt(jwt);

        sendEventByEventObject(jwt, {
            event_type: AmplitudeEventType.ActivationCode_Prerequisite_Question,
            event_properties: {
                entry_flow: accounts[0]?.idTokenClaims?.newUser ? 'New Account creation' : 'Existing Account',
            },
        });

        // Update the page state based on the clear session cookie
        if (verificationSessionId) {
            const response = await getIdentityVerificationSession(jwt, pimsApiUrl, VENDOR_NAME, verificationSessionId);

            if (response.status === SessionStatus.IN_PROGRESS && response.token) {
                setPageState(PageState.ExistingSession);
                setToken(response.token);
            } else if (response.status === SessionStatus.COMPLETED) {
                // A user with an already completed and successful verification session should proceed to the verification redirect page
                setPageState(PageState.Loading);
                window.location.replace(AuthenticatedRoutes.RootRoutes.VerificationRedirect);
            } else {
                setPageState(PageState.NewSession);
            }
        }
    };

    useEffect(() => {
        checkForExistingSession();
    }, [verificationSessionId]);

    const postVerificationSessionParams: PimsPostVerificationSessionRequest = {
        jwt,
        pimsApiUrl,
        brand,
        ehrSystem,
        deviceType: getDeviceCategory(),
        redirectUri,
        vendor: CLEAR_VENDOR_NAME,
    };

    const redirectToCustomerService = (error: Error) => {
        errorHistoryPush(
            AuthenticatedRoutes.VerificationRoutes.ContactCustomerService,
            `IdentityVerificationPage(): Error occurred - ${error.message}`,
        );
    };

    const updateSessionCookie = (verificationSessionId: string) => {
        setCookie(verificationSessionCookieName, verificationSessionId);
    };

    const useDemographicsFlow = () => {
        sendEventByType(AmplitudeEventType.ActivationCode_Prerequisite_Deny, jwt);
        history.push({
            pathname: AuthenticatedRoutes.VerificationRoutes.StartDemographicsFlow,
            // If we arrive here without this is_cross_region query param, we need to propagate it to the next page via the state.
            // TODO: Replace all this propagation with a React context
            state: { isCrossRegion },
        });
    };

    const useActivationCodeFlow = () => {
        sendEventByType(AmplitudeEventType.ActivationCode_Prerequisite_Confirm, jwt);
        history.push(AuthenticatedRoutes.VerificationRoutes.ActivationCodeFlow);
    };

    const clearConfig: ClearConfig = identityVerification.find(
        (option) => option.vendor === CLEAR_VENDOR_NAME,
    )?.options;

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <BrandedCard maxWidth={452} minHeight={530} isLoading={pageState === PageState.Loading}>
            <BrandedCardContent marginTop={48} paddingLeft={48} paddingRight={48}>
                <TypographyI18N variant='subtitle1' className={classes.header}>
                    To complete sign-up, choose one of the following options:
                </TypographyI18N>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={
                                <TypographyI18N variant='subtitle1' className={classes.listItemTypography}>
                                    Continue with our partner, CLEAR
                                </TypographyI18N>
                            }
                        />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={
                                <TypographyI18N variant='subtitle1' className={classes.listItemTypography}>
                                    Enter the activation code provided by one of our clinics
                                </TypographyI18N>
                            }
                        />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={
                                <TypographyI18N variant='subtitle1' className={classes.listItemTypography}>
                                    Use the basic activation process
                                </TypographyI18N>
                            }
                        />
                    </ListItem>
                </List>
            </BrandedCardContent>
            <BrandedCardActions direction='column' gap={16} height={214} justifyContent='flex-start'>
                <ClearButton
                    config={clearConfig}
                    onError={redirectToCustomerService}
                    onSessionCreated={updateSessionCookie}
                    params={postVerificationSessionParams}
                    token={token}
                    width={240}
                />
                <Button
                    backgroundColor={'initial'}
                    variant='outlined'
                    width={240}
                    paddingLeft={0}
                    paddingRight={0}
                    onClick={useActivationCodeFlow}
                >
                    {t('Use Activation Code')}
                </Button>
                <Button
                    backgroundColor={'initial'}
                    variant='outlined'
                    width={240}
                    paddingLeft={0}
                    paddingRight={0}
                    onClick={useDemographicsFlow}
                >
                    {t('Basic Activation')}
                </Button>
            </BrandedCardActions>
        </BrandedCard>
    );
};

export default withLogger(IdentityVerificationPage, {
    eventSource: 'IdentityVerificationPage',
});
