import { useEffect, useState } from 'react';

const getCookie = (name: string): string | undefined => {
    if (document.cookie) {
        const cookies: string[] = document.cookie.split(';');
        const foundCookie = cookies.find((cookie) => cookie.trim().startsWith(`${name}=`));
        return foundCookie?.split('=')?.[1];
    }
};

export const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value}; Path=/;`;
};

/**
 * Very simple wrapper around document.cookie to get and set cookies.
 * @param name Name of the cookie
 * @returns The value of the cookie and a setter function
 */
const useCookie = (name: string): [string | undefined, typeof updateCookie] => {
    const actualCookieValue = getCookie(name);
    const [_storedCookieValue, setValue] = useState<string | undefined>(actualCookieValue);

    const updateCookie = (value: string) => {
        setCookie(name, value);
        setValue(value);
    };

    useEffect(() => {
        setValue(actualCookieValue);
    }, [name, actualCookieValue]);

    return [actualCookieValue, updateCookie];
};

export default useCookie;
