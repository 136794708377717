import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AmplitudeEventType } from '../../../../analytics/amplitudeEvents';
import { type ClientConfigType } from '../../../../clientConfig';
import { AuthorizationStatusType } from '../../../../router/types';
import { AIAState } from '../../../../types/aiaState';
import { InstantActivationEvidence } from '../../../../types/authorizationEvidence';
import { ClientConfigContext } from '../../../clientConfigContext';
import { useBrand } from '../../../hooks/useBrand';
import { useMyChartRedirect } from '../../../hooks/useMyChartRedirect';
import { useOidcState } from '../../../hooks/useOidcState';
import { sendEventForAIA, setUserId } from '../../../services/amplitudeClient';
import {
    AutoInstantActivationParameters,
    activateMyChart,
    authorizeByInstantActivation,
    getAutoInstantActivationAuthorization,
} from '../../../services/pimsClient';
import { ErrorRedirect, useErrorHistoryPush } from '../../../util/errorUtils';
import getAccessToken from '../../../util/getAccessToken';
import {
    checkSessionAndNavigateToPromotionPage,
    navigateToPromotionPage,
    showAppPromotion,
} from '../../../util/promotionUtils';
import { redirectWithSoftLogout } from '../../../util/sessionTimeout';
import BrandedCard, {
    BrandedCardActions,
    BrandedCardContent,
    BrandedCardHeader,
} from '../../containers/BrandedCard_v2';
import Button from '../../elements/Button';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import AutoInstantActivationCardHeader from './AutoInstantActivationCardHeader';
import CollectDemographicsForm from './CollectDemographicsForm';

type AutoInstantActivationDemographics = {
    birthdate: string;
};

/**
 * State machine:
 * ValidatingState => DemographicsForm => Authorizing
 */
enum PageState {
    ValidatingState,
    DemographicsForm,
    Authorizing,
}

export const AutoInstantActivationAuthorizationPage = ({ log }: ComponentLoggerProps) => {
    const history = useHistory();
    const { instance, accounts } = useMsal();
    const [pageState, setPageState] = useState<PageState>(PageState.ValidatingState);
    const oidcState = useOidcState<AIAState>({ log, isRequired: true });
    const { brand, promotion } = useBrand();
    const { getSamlSsoPage, getLandingPage } = useMyChartRedirect();
    const { handleSubmit, errors, control, formState } = useForm({
        mode: 'onChange',
    });
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const { t } = useTranslation();
    const errorHistoryPush = useErrorHistoryPush();

    const ehrSystem = oidcState?.ehrSystem; // searchParams.get(AUTO_INSTANT_ACTIVATION_PARAMS.EHR_SYSTEM);

    const errorPage = `/error?brand=${brand}`;
    const contactSupportPage = `contact_support_error?brand=${brand}`;
    const isNonPatientProxy = oidcState?.isNonPatientProxy ?? false;

    // Get Azure User Id
    const tokenClaims = accounts[0]?.idTokenClaims;
    const signInName = tokenClaims?.signInName as string;
    const userId = tokenClaims?.sub as string;

    if (!oidcState || !ehrSystem || !signInName || !userId) {
        log.warn('AutoInstantActivation(): no ehrSystem, signInName or userId found');
        return <ErrorRedirect to={errorPage} message='no ehrSystem, signInName or userId found' />;
    }

    const { isDirty, isValid } = formState;

    // If the user cancels on the AIA flow, redirect them to the MyChart landing page
    const handleCancel = () => {
        const cancelUrl = getLandingPage(ehrSystem);
        redirectWithSoftLogout(instance, cancelUrl);
    };

    /**
     * Get the aia code from the session storage, or if that does not exist, fetch via the cache.
     * Returns undefined if neither sources finds a token.
     */
    const fetchInstantActivationCode = async (oidcStateToken: string, jwt: string): Promise<string | null> => {
        let aiaCode = sessionStorage.getItem('aiaCode');
        if (!aiaCode) {
            const userAgent = window.navigator.userAgent;
            log.warn('Cannot access session storage for temp token. Fetching from server', {
                details: userAgent.substring(0, 99), // length restriction
            }); // need to capture the user agents that are failing to fetch from the sessionStorage.

            const tokenParam = new URLSearchParams({ oidcStateToken });
            const response = await fetch('/instantactivation/code?' + tokenParam.toString(), {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + jwt,
                },
            });
            const result = await response.json();
            aiaCode = result.aiaCode;
        }
        return aiaCode;
    };

    // Check to see if the user is already authorized (with the correct EPI)
    const getAuthorization = async () => {
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
        const oidcStateToken = oidcState.instantActivationCode;
        const instantActivationCode = await fetchInstantActivationCode(oidcStateToken, jwt);
        if (!instantActivationCode) {
            errorHistoryPush(contactSupportPage, 'Cannot obtain original aia code');
            return;
        }
        const response = await getAutoInstantActivationAuthorization(
            jwt,
            clientConfiguration.pimsApiUrl,
            ehrSystem,
            instantActivationCode,
        );

        switch (response.aiaCodeAuthorizationStatus) {
            case AuthorizationStatusType.Authorized: {
                const result = await activateMyChart(jwt, clientConfiguration.pimsApiUrl, ehrSystem);
                if (!result.wasSuccessful) {
                    if (result.error) {
                        log.error(`AutoInstantActivation(): Unable to reactivate MyChart. Error: ${result.error}`);
                    } else {
                        log.warn(`AutoInstantActivation(): Unable to reactivate MyChart`);
                    }
                }
                const myChartUrl = getSamlSsoPage(ehrSystem);
                if (!myChartUrl) {
                    errorHistoryPush(errorPage, 'No MyChart URL found for the authorized system');
                }
                if (promotion && showAppPromotion(promotion)) {
                    navigateToPromotionPage(history, myChartUrl);
                } else {
                    redirectWithSoftLogout(instance, myChartUrl);
                }
                break;
            }
            case AuthorizationStatusType.Unauthorized: {
                //logger.info(`aiaRedirect(): Record with healthSystemId: ${state.healthSystemId} is unAuthorized for ehrSystem: ${state.ehrSystem} redirecting to demographics page`);
                setPageState(PageState.DemographicsForm);
                break;
            }
            default:
                if (isNonPatientProxy) {
                    errorHistoryPush(
                        contactSupportPage,
                        'An error occured in the authorization lookup for non patient proxy',
                    );
                } else {
                    errorHistoryPush(errorPage, 'An error occured in the authorization lookup for patient');
                }
                break;
        }
    };

    // Authorize the user by activation code and DOB demographics match
    const onSubmit: SubmitHandler<AutoInstantActivationDemographics> = async (data) => {
        setPageState(PageState.Authorizing);

        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
        sendEventForAIA(jwt, AmplitudeEventType.SSOSignUp_AIA_RecordMatch_Info_Next);

        const oidcStateToken = oidcState.instantActivationCode;
        const instantActivationCode = await fetchInstantActivationCode(oidcStateToken, jwt);
        if (!instantActivationCode) {
            errorHistoryPush(contactSupportPage, 'Cannot obtain original aia code');
            return;
        }
        const authorizationEvidence: InstantActivationEvidence = {
            instantActivationCode: instantActivationCode,
            dateOfBirth: data.birthdate,
        };

        const additionalParameters: AutoInstantActivationParameters = {
            signin: signInName,
            userId: userId,
            ehrSystem,
            brand,
        };

        const response = await authorizeByInstantActivation({
            jwt,
            authorizationEvidence,
            pimsApiUrl: clientConfiguration.pimsApiUrl,
            system: ehrSystem,
            additionalParameters,
        });

        if (response.ok) {
            sendEventForAIA(jwt, AmplitudeEventType.SSOSignUp_AIA_RecordMatch_Success);
            let authorizedSystem = '';
            try {
                authorizedSystem = (await response.json()).authorizedSystem;
            } catch (err: any) {
                errorHistoryPush(errorPage, `Could not parse JSON response. ${err.message}`);
                return;
            }

            const myChartUrl = getSamlSsoPage(ehrSystem);
            if (!myChartUrl) {
                errorHistoryPush(
                    errorPage,
                    `Invalid epic configuration, no mychart url found for ${authorizedSystem} ${brand}`,
                );
            } else {
                if (promotion && showAppPromotion(promotion)) {
                    navigateToPromotionPage(history, myChartUrl);
                } else {
                    redirectWithSoftLogout(instance, myChartUrl);
                }
            }
        } else {
            // Non Patient Proxy Demographics Validation Failure Should not go to Prove Flow instead go to Contact Support Page.
            log.error(
                `AutoInstantActivation(): NonPatientProxy :${isNonPatientProxy} : Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}` : ''}`,
            );
            if (isNonPatientProxy && response.status === 400) {
                errorHistoryPush(contactSupportPage, 'Non Patient Proxy Demographics Validation Failure');
            } else if (response.status === 400) {
                errorHistoryPush(errorPage, 'Patient Demographics Validation Failure');
            } else {
                errorHistoryPush(errorPage, 'Error occurred in the authorization process');
            }
        }
    };

    useEffect(() => {
        // If the user is returning here via the back button from an app store via App Promotion, then redirect them to the promotion page
        if (promotion && showAppPromotion(promotion)) {
            const redirectUrl = getSamlSsoPage(ehrSystem);
            checkSessionAndNavigateToPromotionPage(history, redirectUrl);
        }

        // ???
        window.scrollTo(0, 0);

        // Amplitude event: SSO - SignUp - AIA - Record Match - Info
        setUserId(userId);
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) => {
            sendEventForAIA(jwt, AmplitudeEventType.SSOSignUp_AIA_RecordMatch_Info);
        });

        // Determine if the user is already authorized and can skip this page and proceed to MyChart
        getAuthorization();
    }, []);

    return (
        <BrandedCard
            maxWidth={450}
            minHeight={530}
            isLoading={pageState === PageState.Authorizing || pageState === PageState.ValidatingState}
        >
            <BrandedCardHeader>
                <AutoInstantActivationCardHeader loading={pageState === PageState.Authorizing} />
            </BrandedCardHeader>
            <BrandedCardContent>
                <CollectDemographicsForm control={control} errors={errors} infoComponent='' />
            </BrandedCardContent>
            <BrandedCardActions>
                <button className='cancelButton' type='button' onClick={handleCancel}>
                    {t('Cancel')}
                </button>
                <Button
                    disabled={!isDirty || !isValid}
                    type='button'
                    variant='contained'
                    onClick={handleSubmit(onSubmit)}
                >
                    {t('Next')}
                </Button>
            </BrandedCardActions>
        </BrandedCard>
    );
};

export default withLogger(AutoInstantActivationAuthorizationPage, {
    eventSource: 'AutoInstantActivationAuthorizationPage',
});
